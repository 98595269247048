export const LEAVE_STATUS = {
    PENDING: 0,
    APPROVED: 1,
    REJECTED: -1
}
export const LEAVE_TYPE = {
    CL: 1,
    SL: 2,
    
    COFF: 3,
    LWP: 4,
    MULTI_LEAVES: 5
}
export const LEAVE_NAMES = [
    {
        label: "CL",
        fullname: "Casual Leave",
        value: LEAVE_TYPE.CL,
        color: "#D78329",
        background: '#FEF2E1'

    },
    {
        label: "SL",
        fullname: "Sick Leave",
        value: LEAVE_TYPE.SL,
        color: "#368B8B",
        background: "#E5F6F3"
    },
    // {
    //     label: "PL",
    //     fullname: "Paid Leave",
    //     value: LEAVE_TYPE.PL,
    //     color: "#5E8233",
    //     background: "#F8FAE5"
    // },
    {
        label: "COFF",
        value: LEAVE_TYPE.COFF,
        fullname: "Compensatory Leave",
        color: "#8B5E36",
        background: "#F6EDE5"
    },
    {
        value: LEAVE_TYPE.LWP,
        label: "LWP",
        fullname: "LWP",
        color: "#D70000",
        background: "#FFBBBC"
    },
    {
        value: LEAVE_TYPE.MULTI_LEAVES,
        label: "Multi Leaves",
        fullname: "Multi",
        color: "green",
        background: "#E5E7FA"
    }
]