import { CampaignOutlined, EventAvailable, EventAvailableOutlined, History, HourglassEmpty } from '@mui/icons-material'
import { Box, Button, ButtonBase, Grid, styled, useMediaQuery, useTheme } from '@mui/material'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from '../../store/actions/modalAction'
import { Add } from '@mui/icons-material'
import { center } from '../../assets/css/theme/common'
import { Link, useLocation } from 'react-router-dom'

import { ActionButton } from '../../components/button/SubmitButton'



const LeaveBtnBox = {
  width: '100%',

  display: 'flex',
  alignItems:"flex-start"



}

const EmployeeTaskButtons = ({ userId }) => {

  const location = useLocation()
  const pathArr = location.pathname.split('/')
  const slug = pathArr[pathArr.length - 1]

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  // if(desktop)
  return (
    <>
      <Box sx={LeaveBtnBox} mt={5} mb={5}>
        {!desktop && <>

          <ActionButton

            

            sx={{ borderRadius: '4px' }}
            LinkComponent={Link}
            to="/tasks/create"
            title="Add Task"
            icon={<Add />}
          >

          </ActionButton>
          <Box ml={2} />
        </>}
        <ActionButton
          isFirst={true}
          LinkComponent={Link}
          active={slug == '' || slug == 'calendar'}
          to="calendar"

          sx={{ borderRadius: '4px' }}
          title={"Work Calender"}
          icon={<EventAvailable />}

        >

        </ActionButton>
        <Box ml={2} />
        <ActionButton
          active={slug == 'daily-update'}

          sx={{ borderRadius: '4px' }}
          LinkComponent={Link}
          to="daily-update"
          title={"Daily Update"}
          icon={<History />}
        >
        </ActionButton>
        <Box ml={2} />
        <ActionButton

          active={slug == 'task-timeline'}

          sx={{ borderRadius: '4px' }}
          LinkComponent={Link}
          to="task-timeline"
          title="Task Timeline"
          icon={<HourglassEmpty />}
        >

        </ActionButton>


      </Box>
    </>
  )
}
export default EmployeeTaskButtons
