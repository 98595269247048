import { Box, useTheme, Paper, useMediaQuery, Typography } from "@mui/material"
import { useSelector, useDispatch } from 'react-redux'
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom"



import { useEffect, useState } from "react"
import { memo } from "react"
import AppModeLabel from "../../texts/AppModeLabel"
import { center } from "../../../assets/css/theme/common"
import PopUpModal from "../../Modal"
import { Logo } from "./Logo"
import Header from "./Header"
import Navbar from "./Navbar"
import responsive from "../../../assets/css/responsive"
import MobileNav from "./MobileNav"
import colorTheme from "../../../assets/css/theme/colorTheme"
import { fetchHolidayListAction } from "../../../store/actions/holidayListAction"
import { lastReadVersion } from "../../../utils/helper"
import { openModal } from "../../../store/actions/modalAction"
import VersionDetailsController from "../../../pages/versions/VersionDetailsController"
import BirthdayWishModal from "../../../pages/birthday/BirthdayWishModal"
import BirthdayContainer from "../../../pages/birthday/BirthdayContainer"






const containerStyle = (theme) => ({ height: "100%", width: "100%", display: "flex",  ...responsive.containerStyle })
const mobileContainerStyle = (theme) => ({ height: "100%", width: "100%", display: "flex", overflow: "hidden", ...responsive.mobileContainerStyle })
const navBarOuterStyle = (theme) => ({ height: "calc(100vh-100px)", position: "relative" })

const navbarInnerStyle = (theme) => ({borderRadius:0, width: "260px", background: "white", height: "100%", overflowY: "auto", position: "relative", 'scrollbar-width': "0px", "::-webkit-scrollbar": { display: 'none' } })

const logoStyle = (theme) => ({ position: "sticky", top: "0px", background: "inherit", borderBottom: "1px solid " ,borderColor:"divider", height: "70px", ...center, zIndex: 11 })
const boxStyle = (theme) => ({
  width: "100%",
  maxHeight: "100vh",
  background: theme.palette.grey['400'],
  overflow: "scroll"
})


const AppContainer = (props) => {
  const dispatch = useDispatch()


  const location = useLocation()
  let splittedPath = [...location.pathname.split('/').slice(1)]

  const params = useParams()
  if (params.id || params.time) {
    splittedPath[splittedPath.length - 1] = ""
  }

  const ActivePathComponent = (activeComponentProps) => {
    let path = '/'
    return <Box sx={{ display: "flex" }} {...activeComponentProps} >
      {splittedPath.map((item, index) => {

        path += item + "/ ";

        return <Link to={index == 0 ? "" : item} key={item} >
          <Typography variant="h6" color="text.primary">
            {item ? (item + '/') : ""}
          </Typography> </Link>
      })}
    </Box>
  }

  const ActivePath = {
    textTransform: "capitalize",
    margin: "24px 0 0 24px",
    color: colorTheme.palette.text[300],
    cursor: "pointer"
  }
  const navigate = useNavigate();
  const user = useSelector(state => state.user)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    dispatch(fetchHolidayListAction())
    const lastVersion = lastReadVersion.get()
    const splittedVersion = lastVersion?.split?.('-') ?? []

    if (user.data.web_version && (!lastVersion || splittedVersion[0] != user.data.web_version?.main || splittedVersion[1] != user.data.web_version?.sub)) {
      dispatch(openModal({
        title: "New Update",
        component: <VersionDetailsController />
      }))
    }

  }, [])

  return (
    <>
      {
        process.env.REACT_APP_APP_MODE != 'production' && <AppModeLabel />
      }
      {
        <BirthdayWishModal subTitle={user.data.first_name} />
      }

      <PopUpModal />
      {!isMobile && <Box sx={containerStyle}>
        <Box elevation={2} sx={navBarOuterStyle}>
          <Paper elevation={2} sx={navbarInnerStyle}>
            <Box sx={logoStyle} mb={4}>
              <Logo sx={{ zIndex: 1 }} />
            </Box>
            <Box sx={{ position: "relative", zIndex: 1 }}>
              <Navbar />
            </Box>
          </Paper>
        </Box>


        <Box sx={boxStyle}>
          <Header />

          <Box sx={ActivePath} component={ActivePathComponent} ></Box>
          <Box pb={3} pr={4} pl={4} sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <BirthdayContainer />
            <Outlet />
            {props.children}
          </Box>
        </Box>
      </Box >
      }

      {isMobile && <Box sx={mobileContainerStyle}>
        <Box sx={(theme)=>({ height: "100%", width: "100%", overflow: "hidden", display: "flex", flexDirection: "column",...boxStyle(theme) })}>
          <Header />
          <Box pb={3} pr={2} pl={2} sx={{ display: "flex", flex: 1, flexDirection: "column", overflowY: "scroll" }}>
            <Outlet />
            {props.children}
          </Box>
          <MobileNav />
        </Box>
      </Box>}



    </>
  )
}
export default memo(AppContainer)