import { Box, Typography } from "@mui/material";

import BackGroundLogo from "../../../assets/images/background.png";
import responsive from "../../../assets/css/responsive";

const outerBoxStyle = (theme) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    flexDirection: "column",
    ...responsive.outerBoxStyle,
  };
};

const heading = {
  fontFamily: "Azonix Regular",
  src: `local('Azonix Regular'), url('../../../assets/fonts/azonix-cufonfonts-webfont/Azonix.woff') format('woff')`,
  fontSize: "48px",
  fontWeight: 400,
  lineHeight: "60px",
  letterSpacing: "0.315em",
  color: "#FFFFFF",
};

const BackGroundStyle = {
  background: `url(${BackGroundLogo})`,
  objectFit: "cover",
  width: "100%",
};

const text = {
  fontFamily: "Azonix",
  fontFamily: "Azonix Regular",
  src: `local('Azonix Regular'), url('../../../assets/fonts/azonix-cufonfonts-webfont/Azonix.woff') format('woff')`,
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "32px",
  letterSpacing: "0.315em",
  color: "#FFFFFF",
};



const AuthLeftContainer = () => {
  return (
    <Box sx={BackGroundStyle}>
      <Box sx={outerBoxStyle}>
       
       
      </Box>
    </Box>
  );
};
export default AuthLeftContainer;
