// import { useEffect, useMemo, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { getUserByIdApi, updateUserField } from '../../apis/user.api'
// import { callApiAction } from '../../store/actions/commonAction'
// import { loggedInUser } from '../../utils/helper'
import HolidayUI from './HolidayUI'
// import {
//   createHoliday,
//   fetchHolidays,
//   deleteHoliday,
// } from '../../apis/holiday.api'
// import moment from 'moment'
// import DeleteHolidayButton from './DeleteHolidayButton'
// import { fetchHolidayDataAction } from '../../store/actions/settingsAction'


// const HolidayController = ({ userId }) => {
//   const user = useSelector((state) => state.user)
//   const dispatch = useDispatch()

//   const [state, setState] = useState([])
//   const [loading, setLoading] = useState(false)
//   const [fields, setFields] = useState({ year: moment(),pageNo:1,pageSize:50 })
//   const {settings} = useSelector((state) => state)

//   const columns = useMemo(() => [
//     { id: 1, fieldName: 'name', label: 'Name', align: "left", sort: true, minWidth: '150px' },
//     { id: 2, fieldName: 'date', label: 'Date', align: "left", sort: true, minWidth: '150px',renderValue:(params)=>moment(params.date).format("DD/MM/YYYY") },
//     { id: 3, fieldName: 'delete', label: 'Date', align: "left",renderValue:(params,setParams)=><DeleteHolidayButton params={params} setParams={setParams} /> },
//   ], [dispatch]);

// const fetchHolidaysApi = fetchHolidays
// const deleteHolidayApi = deleteHoliday


//   const fetchList = () => {
//     setLoading(true)
//     dispatch(
//       callApiAction(
//         async () => await fetchHolidaysApi({ ...fields,date: fields.year.valueOf() }),
//         (response) => {
//           setState(response)
//           // console.log(state)
//           setLoading(false)
//         },
//         (err) => {
//           setLoading(false)
//         },
//       ),
//     )
//   }

//   const DeleteHoliday = (id) => {

//     setLoading(true)
//     dispatch(
//       callApiAction(
//         async () => await deleteHolidayApi({ id }),
//         (response) => {
//           setLoading(false)
//           window.location.reload(true)
//           // console.log(response)
//         },
//         (err) => {
//           setLoading(false)

//         },
//       ),
//     )
//   }
//   const getHolidayList = () => {
//     const holidayYear = moment(settings.holiday_filters.year).year();
//     const currentYear = moment(fields.year).year()
//     if (!settings.holiday_data || settings.holiday_data.length === 0 || holidayYear != currentYear || !Object.keys(fields).filter(k => k!= 'year').every(k => fields[k] == settings.holiday_filters[k])) {
//         dispatch(fetchHolidayDataAction(fields));
//     }
// }
//   useEffect(() => {
//     // fetchList()
//     getHolidayList()
//   }, [fields])

//   return (
//     <HolidayUI
//       setFields={setFields}
//       fields={fields}
//       setState={setState}
//       loading={settings.holiday_loading}
//       callBack={getHolidayList}
//       state={settings.holiday_data}
//       DeleteHoliday={DeleteHoliday}
//       columns={columns}
//     />
//   )
// }
// export default HolidayController
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchHolidays, deleteHoliday } from '../../apis/holiday.api'
import moment from 'moment'
import DeleteHolidayButton from './DeleteHolidayButton'
import { fetchHolidayDataAction } from '../../store/actions/settingsAction'
import { callApiAction } from '../../store/actions/commonAction'

const HolidayController = ({ userId }) => {
  const dispatch = useDispatch()
  
  const [loading, setLoading] = useState(false)
  const [fields, setFields] = useState({ year: moment(), pageNo: 1, pageSize: 50 })
  const [state, setState] = useState([])
  
  const columns = useMemo(() => [
    { id: 1, fieldName: 'name', label: 'Name', align: "left", sort: true, minWidth: '150px' },
    { id: 2, fieldName: 'date', label: 'Date', align: "left", sort: true, minWidth: '150px', renderValue: (params) => moment(params.date).format("DD/MM/YYYY") },
    { id: 3, fieldName: 'delete', label: 'Delete', align: "left", renderValue: (params, setParams) => <DeleteHolidayButton params={params} setParams={setParams} /> },
  ], []);

  
  const fetchList = () => {

    setLoading(true)
    dispatch(
      callApiAction(
        async () => await fetchHolidays({ ...fields, date: fields.year.valueOf() }),
        (response) => {
          
          setState(response)
          setLoading(false)
        },
        error => {          
          setLoading(false)
        }

      ),

    )
  }

  
  const deleteHoliday = (id) => {
    setLoading(true)
    dispatch(
      deleteHoliday({ id })
    ).then(response => {

      setLoading(false)
      window.location.reload(true)
    }).catch(error => {
      // Handle error
      setLoading(false)
    })
  }

  
  useEffect(() => {
    fetchList()
  }, [fields]);

  return (
    <HolidayUI
      setFields={setFields}
      fields={fields}
      loading={loading}
      callBack={fetchList}
      state={state}
      deleteHoliday={deleteHoliday}
      columns={columns}
    />
  )
}

export default HolidayController
