import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { callApiAction } from "../../store/actions/commonAction"
import LogDetailsUi from "./LogsDetailsUi"
import { CALENDAR_ITEM_TYPES, LOGS_NAMES, SNACK_BAR_VARIETNS } from "../../utils/constants"
import { getDailyLogsApi } from "../../apis/leave.api"
import { calculateWorkingHours } from "../../utils/helper"
import moment from "moment"
import DashboardLogsUI from "../dashboard/DashboardLogsUI"
import DashBox from "../dashboard/Dashbox"
import CheckInCheckoutButton from "../attendences/CheckInCheckoutButton"
import { Box } from "@mui/material"

const LogDetailsController = ({ date, userId, dashboard }) => {
    const dispatch = useDispatch()

    const [list, setList] = useState({})
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState({})
    const fetchList = async () => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getDailyLogsApi({ date, userId, duration: 'daily' }),
                async (response) => {
                    setLoading(false)

                    const logsWithOutPeriodic = response && Array.isArray(response) ? response
                        .filter(item => [CALENDAR_ITEM_TYPES.checkInLog, CALENDAR_ITEM_TYPES.checkOUTLog, CALENDAR_ITEM_TYPES.breakOUTLog, CALENDAR_ITEM_TYPES.breakInLog].includes(item.logType))
                        .map((item) => ({
                            ...item,
                            logTime: item.createdAt,
                            type: item.logType,
                            lat: item.location?.coordinates?.[1],
                            lng: item.location?.coordinates?.[0],
                            location_id: item.location_id
                        }))

                        : []

                    const logsWithPeriodic = response && Array.isArray(response) ? response
                        .filter(item => ![CALENDAR_ITEM_TYPES.checkInLog, CALENDAR_ITEM_TYPES.checkOUTLog, CALENDAR_ITEM_TYPES.breakOUTLog, CALENDAR_ITEM_TYPES.breakInLog].includes(item.logType))
                        .map((item) => ({
                            logTime: item.createdAt,
                            type: item.logType,
                            lat: item.location?.coordinates?.[1],
                            lng: item.location?.coordinates?.[0],
                            location_id: item.location_id
                        }))

                        : []

                    let finalData = {
                        ...calculateWorkingHours(logsWithOutPeriodic),
                        logsWithPeriodic,
                        logsWithOutPeriodic,
                        allLogs: response && Array.isArray(response) && response.map((item) => ({
                            logTime: item.createdAt,
                            address: LOGS_NAMES[item.logType] + "-" + (moment(item.createdAt).format("HH:mm")),
                            lat: item.location?.coordinates?.[1],
                            lng: item.location?.coordinates?.[0],
                            location_id: item.location_id
                        }))
                    }
                    setList(finalData)

                },
                (err) => {
                    setLoading(false)


                    setList({})
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                },
            ),
        )

    }
    useEffect(() => {
        fetchList()
    }, [userId])
    
    return <>
        {!dashboard && (
            <LogDetailsUi
                filters={filters}
                setFilters={setFilters}
                date={date}
                list={list}
                loading={loading}
            />
        )}
        {dashboard && (
            <>
            
            <DashBox title={"Working Hours"} showDate={true} showTodayDate={true}>
          
                <DashboardLogsUI
                    filters={filters}
                    setFilters={setFilters}
                    date={date}
                    list={list}
                    loading={loading}
                />
                  <Box px={3} pt={1} pb={3} >
            <CheckInCheckoutButton />
            </Box>
            </DashBox>
            </>
        )}

    </>
}
export default LogDetailsController