import { Circle } from "@mui/icons-material";
import { Paper, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker";
import CalendarController from "../calendar/CalendarController";

import LeaveBalance from "./LeaveBalance";
import LeaveButtons from "./LeaveButtons";
import { memo, useEffect } from "react";
import { Helmet } from "react-helmet";
import AnimatedFireCrackers from "../../components/animations/AnimatedFireCrackers";

const MyLeaveUi = ({ date, setDate, callBack }) => {
  const user = useSelector((state) => state.user);

  return (
    <>
      <Box mt={4}></Box>
      <LeaveButtons userId={user.data._id} callBack={callBack} />

      <Box mt={3} />
      <Box sx={{ display: "flex", height: "unset" }}>
        <CustomMonthPicker date={date} setDate={setDate} />
      </Box>
      <Box mt={3} />

      <LeaveBalance date={date} userId={user.data._id} />

      <Box mt={4}>
        <CalendarController
          weeklyOf={user.data.weekly_of}
          userId={user.data._id}
          calendarTimeObj={date && date.toDate()}
        />
      </Box>
    </>
  );
};
export default memo(MyLeaveUi);
