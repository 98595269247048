import {
  Box,


  Typography,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'

import { useSelector } from 'react-redux'
import SubmitButton from '../../../components/button/SubmitButton'
import CustomInput from '../../../components/inputs/CustomInput'
import CustomRadioButtons from '../../../components/layouts/common/CustomRadioButtons'

import { LEAVE_NAMES, LEAVE_TYPE } from '../../../utils/leave.constants'
import { UserSearchBarNormal } from '../../../components/inputs/SearchBar'
import LeaveBalance from '../LeaveBalance'
import moment from 'moment'
import { useMemo } from 'react'



const ManualLeaveRaiseUi = ({

  onSubmit,
  loading,
  fields,
  setFields

}) => {
  const { holiday } = useSelector((state) => state)


  const previousYearDate = useMemo(() => moment(fields.date).add(-1, "month"), [])


  const onDateChange = (e) => {
    const changedVal = {}
    changedVal['date'] = e
    setFields({ ...fields, err: '', ...changedVal })
  }


  return (
    <Box p={4} mt={1} component="form" onSubmit={onSubmit}>

      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>

        <Box >
          <Typography variant='h2'>
            Settle Leaves
          </Typography>
          <Typography variant='h6' color="error">
            {fields.err}
          </Typography>
        </Box>

      </Box>
      <Box sx={{ display: "flex" }} mt={4}>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>

          <Typography variant='h3' mt={3}>
            Select Employee:
          </Typography>


          <Box mt={2}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                height: '62px',
              }}
            >
              <Box sx={{ display: 'flex', width: '100%' }}>
                <UserSearchBarNormal
                  placeholder="search"
                  inputProps={{ placeholder: "Search & Select Employee" }}
                  value={fields.userId}
                  onChange={(newVal) => {
                    setFields({ userId: newVal?._id })
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Typography variant='h3' mt={3}>
            Leave Date:
          </Typography>


          <Box mt={2}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                height: '62px',
              }}
            >
              <Box sx={{ display: 'flex', width: '100%' }}>
                <DesktopDatePicker
                  views={["month", "year"]}

                  renderInput={(props) => {
                    return <CustomInput {...props} sx={{ height: '100%' }} />
                  }}
                  inputFormat="MMM, yyyy"

                  disabled={
                    loading ||
                    !(holiday.data && Array.isArray(holiday.data)) ||
                    holiday.loading
                  }
                  value={fields.date}
                  onChange={onDateChange}
                  type="date"
                />


              </Box>
            </Box>
          </Box>
          {fields.userId && <Box mt={3} >
            <LeaveBalance userId={fields.userId} date={fields.date?.startOf('month')} />
          </Box>}
          {<>

            <Typography variant='h3' mt={3}>
              Leave Type:
            </Typography>
            <Box sx={{ display: "flex", flex: "none" }} mt={3}>
              <CustomRadioButtons
                options={
                  // [
                  //   {
                  //     value: LEAVE_TYPE.CL,
                  //     label: "CL"
                  //   },
                  //   {
                  //     value: LEAVE_TYPE.COFF,
                  //     label: "COFF"
                  //   }
                  // ]
                  LEAVE_NAMES.filter((item) => item.value != LEAVE_TYPE.MULTI_LEAVES)
                }
                value={fields.type}
                onChange={(e) => {
                  setFields({ ...fields, type: e.target.value })
                }}
              />
            </Box>
          </>}

          <Typography variant='h3' mt={3}>
            Balance Count:
          </Typography>
          <CustomInput
            disabled={loading}
            value={fields.count}
            onChange={(e) =>
              setFields({ ...fields, err: '', count: e.target.value })
            }
            type="number"

            placeholder="Write count of leave..."

          />


          <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>

            <Box sx={{ display: "flex" }} mr={3}>

            </Box>
            <Box sx={{ display: "flex" }}>
              <SubmitButton
                disabled={loading}
                loading={loading}
                type="submit"
                variant="contained"
                color="primary"
                title=" Settle  Leave"
              >

              </SubmitButton>
            </Box>
          </Box>
        </Box>

      </Box>
    </Box>
  )
}
export default ManualLeaveRaiseUi
