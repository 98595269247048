import axios from "axios";
import {  getHeaders } from "../utils/helper";
import endpoints from "./endpoints";



export const getAttendecesStatusAPi = async params => {
  const callResponse = await axios({
    url: endpoints.checkLogStatus,
    method: "get",
    headers: getHeaders(),
    params,

  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const createAttendenceApi = async data => {
  const callResponse = await axios({
    url: endpoints.createLog,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
