import axios from "axios"
import { memo, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"
import { Autocomplete, Grid } from "@mui/material"
import CustomInput from "../../components/inputs/CustomInput"

const CountryStateCityDropDown = ({ country, state, city, onChange = () => { } }) => {

    const API_KEY = 'KOu8ur2aZ1OAJpSp0VwuPTAhKA1MAj3o2I5CoQsBhTyfnlU-MtaRyJnoenmIHjYsRvU'

    const [fields, setFields] = useState({
        country,
        state,
        city
    })
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    const [countriesLoading, setCountiesLoading] = useState(false)
    const [statesLoading, setStateLoading] = useState(false)
    const [citiesLoading, setCitieLoading] = useState(false)

    const dispatch = useDispatch()

    const AUTH_TOKEN = {
        get: () => localStorage.getItem("COUNTRY_STATE_TOKEN"),
        set: (token) => localStorage.setItem("COUNTRY_STATE_TOKEN", token),
        remove: () => localStorage.removeItem("COUNTRY_STATE_TOKEN"),
    }
    const fetchToken = async () => {

        await axios({
            url: "https://www.universal-tutorial.com/api/getaccesstoken/",
            method: "GET",

            headers: {
                "Authorization": "Bearer " + AUTH_TOKEN.get(),
                "api-token": API_KEY,
                "user-email": "sohampatel9403@gmail.com"
            }
        })
            .then((response) => {
                AUTH_TOKEN.set(response.data.auth_token)
                return
            })
            .catch(() => {
                dispatch(callSnackBar("OOPS! somethig went wrong!", SNACK_BAR_VARIETNS.error))
                return
            })
    }

    const fetchCountry = async () => {
        if (!AUTH_TOKEN.get()) {
            await fetchToken()
        }
        setCountiesLoading(true)
        await axios({
            url: "https://www.universal-tutorial.com/api/countries/",
            method: "GET",
            headers: {
                "Authorization": "Bearer " + AUTH_TOKEN.get(),
                "Accept": "application/json"
            }
        })
            .then((response) => {
                setCountries(response.data)
                setCountiesLoading(false)
            })
            .catch(async (err) => {
                const data = err.response.data

                if (data.error && data.error.name === "TokenExpiredError") {
                    await fetchToken()
                    await fetchCountry()
                } else {
                    dispatch(callSnackBar("Cant fetch countries", SNACK_BAR_VARIETNS.error))
                    setCountiesLoading(false)
                }
            })

    }


    const fetchState = async () => {
        setStateLoading(true)
        await axios({
            url: "https://www.universal-tutorial.com/api/states/" + fields.country,
            method: "GET",
            headers: {
                "Authorization": "Bearer " + AUTH_TOKEN.get(),
                "Accept": "application/json"
            }
        })
            .then((response) => {
                setStates(response.data)
                setStateLoading(false)
            })
            .catch(async(err) => {
                const data = err.response.data

                if (data.error && data.error.name === "TokenExpiredError") {
                    await fetchToken()
                    await fetchState()
                } else {
                dispatch(callSnackBar("Cant fetch states", SNACK_BAR_VARIETNS.error))
                setStateLoading(false)
                }
            })

    }

    const fetchCity = async () => {
        setCitieLoading(true)
        await axios({
            url: "https://www.universal-tutorial.com/api/cities/" + fields.state,
            method: "GET",
            headers: {
                "Authorization": "Bearer " + AUTH_TOKEN.get(),
                "Accept": "application/json"
            }
        })
            .then((response) => {
                setCitieLoading(false)
                setCities(response.data)
            })
            .catch(async(err) => {
                const data = err.response.data

                if (data.error && data.error.name === "TokenExpiredError") {
                    await fetchToken()
                    await fetchCity()
                } else {
                dispatch(callSnackBar("Cant fetch cities", SNACK_BAR_VARIETNS.error))
                setCitieLoading(false)
                }
            })

    }
    useEffect(() => {
        fetchCountry()
    }, [])
    useEffect(() => {
        if (fields.country && fields.country != null) {
            fetchState()
        } else {
            setStates([])
        }
    }, [fields.country])
    useEffect(() => {
        if (fields.state && fields.state != null) {
            fetchCity()
        } else {
            setCities([])
        }
    }, [fields.state])


    const onCountryChangeFun = (e, val) => {
        
        setFields({ ...fields, country: val.value, state: null, city: null })
        onChange(val.value, null, null)

    }
    const onStateChangeFun = (e, val) => {
        setFields({ ...fields, state: val.value, city: null })
        onChange(fields.country, val.value, null)

    }
    const onCityChangeFun = (e, val) => {
        setFields({ ...fields, city: val.value })
        onChange(fields.country, fields.state, val.value)

    }
    return <>
        <Grid item xs={12} md={4}>

            <Autocomplete
                disableClearable
                onChange={onCountryChangeFun}
                autoComplete={false}
                autoHighlight={false}
                disabled={countriesLoading}
                loading={countriesLoading}
                id="country"
                isOptionEqualToValue={(option) => {
                    
                    if (option.value == fields.country) {
                        return true
                    }
                    return false
                }}
                value={fields.country}

                options={countries.map((option) => ({
                    label: option.country_name,
                    value: option.country_name
                }))}
                fullWidth

                renderInput={(params) => <CustomInput autoComplete={false}
                    autoHighlight={false} {...params} label="Country*" />}
            />
        </Grid>
        <Grid item xs={12} md={4}>

            <Autocomplete
                key={fields.country}
                onChange={onStateChangeFun}
                disableClearable
                disabled={statesLoading || !fields.country || fields.country == ''}
                loading={statesLoading}
                id="state"
                value={fields.state}
                isOptionEqualToValue={(option) => {
                    
                    if (option.value == fields.state) {
                        return true
                    }
                    return false
                }}
                options={states.map((option) => ({
                    label: option.state_name,
                    value: option.state_name
                }))}
                fullWidth

                renderInput={(params) => <CustomInput {...params} label="State*" />}
            />
        </Grid>
        <Grid item xs={12} md={4}>
            <Autocomplete
                key={fields.state}
                onChange={onCityChangeFun}
                disableClearable
                disabled={citiesLoading || !fields.state || fields.state == ''}
                loading={citiesLoading}
                id="city"
                value={fields.city}
                isOptionEqualToValue={(option) => {
                    if (option.value == fields.city) {
                        return true
                    }
                    return false
                }}
                options={cities.map((option) => ({
                    label: option.city_name,
                    value: option.city_name
                }))}
                fullWidth

                renderInput={(params) => <CustomInput {...params} label="City*" />}
            />

        </Grid>

    </>

}
export default memo(CountryStateCityDropDown)