
import { memo, useEffect, useState } from "react"
import SubmitButton from "../../components/button/SubmitButton"
import { createAttendenceApi, getAttendecesStatusAPi } from "../../apis/attendence.api"
import { useDispatch, useSelector } from "react-redux"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { CALENDAR_ITEM_TYPES, SNACK_BAR_VARIETNS } from "../../utils/constants"
import moment from "moment"
import { callApiAction } from "../../store/actions/commonAction"
import { Dialog, DialogContent, Grid, Typography } from "@mui/material"
import { Padding } from "@mui/icons-material"

const CheckInCheckOutButton = ({ userId, callBack = () => { }, noDialog }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state)
    const [justCheckout, setJustCheckedOut] = useState(false)
    const [isCheckedIn, setIsCheckIn] = useState(false)
    const [isbreakIn, setIsBreakIn] = useState(false)
    
    const [loading, setLoading] = useState(false)
    const fetchStatus = () => {
        setLoading(true)

        dispatch(callApiAction(
            async () => await getAttendecesStatusAPi({ date: moment().toISOString() }),
            (response) => {
                setLoading(false)
                setIsCheckIn(response?.CheckIn)
                setIsBreakIn(response?.BreakIn)


            },
            (err) => {
                dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                setLoading(false)
            }
        ))
    }
    useEffect(() => {
        fetchStatus()
    }, [])
    const addLog = (logType,lat=0,log=0,justCheckout)=>{
        setLoading(true)
        dispatch(callApiAction(
            async () => await createAttendenceApi({ userId, lat, log, logType }),
            (response) => {
                setLoading(false)
                setJustCheckedOut(justCheckout)
                if([CALENDAR_ITEM_TYPES.checkInLog,CALENDAR_ITEM_TYPES.checkOUTLog].includes(logType))
                setIsCheckIn(logType==CALENDAR_ITEM_TYPES.checkInLog)

                if([CALENDAR_ITEM_TYPES.breakInLog,CALENDAR_ITEM_TYPES.breakOUTLog].includes(logType))
                    
                setIsBreakIn(logType==CALENDAR_ITEM_TYPES.breakInLog)

                callBack()
            },
            (err) => {
                dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                setLoading(false)
            }
        ))
    }
    const onClick = (logType,setJustCheckedOut) => {
   
        let lat = 0.0
        let log = 0.0
        if ("geolocation" in navigator) {
            // Get the user's current location
            navigator.geolocation.getCurrentPosition(function (position) {
                // The user's latitude and longitude are in position.coords.latitude and position.coords.longitude
                lat = position.coords.latitude;
                log = position.coords.longitude;

                addLog(logType,lat,log,setJustCheckedOut)
              

            }, function (error) {
                // Handle errors, if any
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        dispatch(callSnackBar("User denied the request for geolocation.", SNACK_BAR_VARIETNS.error));
                        break;
                    case error.POSITION_UNAVAILABLE:
                        dispatch(callSnackBar("Location information is unavailable.", SNACK_BAR_VARIETNS.error));
                        break;
                    case error.TIMEOUT:
                        dispatch(callSnackBar("The request to get user location timed out.", SNACK_BAR_VARIETNS.error));
                        break;
                    case error.UNKNOWN_ERROR:
                        dispatch(callSnackBar("An unknown error occurred.", SNACK_BAR_VARIETNS.error));
                        break;
                }
                setLoading(false)
            });
        } else {
           
            addLog(logType,lat,log,setJustCheckedOut)
        }

    }

    if (!isCheckedIn && !loading) {
        return <Dialog open={true} >

            <DialogContent>
                <Typography align="center" variant="h5" >
                    {justCheckout ? "Bbye" : 'Welcome'} {user.data.name}
                </Typography>
                {justCheckout && <> <Typography align="center" variant="subtitle1" mt={3} >
                    See you tomorrow
                </Typography>

                    <Typography variant="caption" align="center" mt={3} >
                        Refresh the page if you want to close this popup
                    </Typography>

                </>}
                {!justCheckout && <> <Typography variant="subtitle1" mt={3} mb={3} >
                    Please Check-in to mark your attendence
                </Typography>
                    <SubmitButton disabled={loading} loading={loading} onClick={()=>onClick(CALENDAR_ITEM_TYPES.checkInLog)} color={"dark"} title={"Check In"} /></>}
            </DialogContent>
        </Dialog>
    }

    return <Grid container spacing={2} >
        <Grid item xs={6} >
            <SubmitButton size="small" disableElevation disabled={loading} loading={loading} onClick={()=>onClick(isbreakIn?CALENDAR_ITEM_TYPES.breakOUTLog:CALENDAR_ITEM_TYPES.breakInLog)} sx={{ padding: 2, minWidth: "0px" }} color={'primary'} title={isbreakIn ? "Break Out" : "Break In"} />
        </Grid>
        <Grid item xs={6} >
            <SubmitButton size="small" disableElevation disabled={loading} loading={loading} onClick={()=>onClick(CALENDAR_ITEM_TYPES.checkOUTLog,true)} sx={{ padding: 2, minWidth: "0px" }} color={'error'} title={ "Check Out"} />
        </Grid>

    </Grid>


}
export default memo(CheckInCheckOutButton)
// import { memo, useEffect, useState } from "react"
// import SubmitButton from "../../components/button/SubmitButton"
// import { createAttendenceApi, getAttendecesStatusAPi } from "../../apis/attendence.api"
// import { useDispatch, useSelector } from "react-redux"
// import { callSnackBar } from "../../store/actions/snackbarAction"
// import { CALENDAR_ITEM_TYPES, SNACK_BAR_VARIETNS } from "../../utils/constants"
// import moment from "moment"
// import { callApiAction } from "../../store/actions/commonAction"
// import { Dialog, DialogContent, Grid, Typography } from "@mui/material"
// import { Padding } from "@mui/icons-material"

// const CheckInCheckOutButton = ({ userId, callBack = () => { }, noDialog }) => {
//     const dispatch = useDispatch()
//     const { user } = useSelector(state => state)
//     const [justCheckout, setJustCheckedOut] = useState(false)
//     const [isCheckedIn, setIsCheckIn] = useState(false)
    
//     const [loading, setLoading] = useState(false)
//     const fetchStatus = () => {
//         setLoading(true)

//         dispatch(callApiAction(
//             async () => await getAttendecesStatusAPi({ date: moment().toISOString() }),
//             (response) => {
//                 setLoading(false)
//                 console.log(response)
//                 setIsCheckIn(response?.CheckIn)


//             },
//             (err) => {
//                 dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
//                 setLoading(false)
//             }
//         ))
//     }
//     useEffect(() => {
//         fetchStatus()
//     }, [])
//     const onClick = () => {
//         setLoading(true)
//         let lat = 0.0
//         let log = 0.0
//         if ("geolocation" in navigator) {
//             // Get the user's current location
//             navigator.geolocation.getCurrentPosition(function (position) {
//                 // The user's latitude and longitude are in position.coords.latitude and position.coords.longitude
//                 lat = position.coords.latitude;
//                 log = position.coords.longitude;


//                 dispatch(callApiAction(
//                     async () => await createAttendenceApi({ userId, lat, log, logType: isCheckedIn ? CALENDAR_ITEM_TYPES.checkOUTLog : CALENDAR_ITEM_TYPES.checkInLog }),
//                     (response) => {
//                         setLoading(false)
//                         setJustCheckedOut(isCheckedIn)
//                         setIsCheckIn(!isCheckedIn)

//                         callBack()
//                     },
//                     (err) => {
//                         dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
//                         setLoading(false)
//                     }
//                 ))

//             }, function (error) {
//                 // Handle errors, if any
//                 switch (error.code) {
//                     case error.PERMISSION_DENIED:
//                         dispatch(callSnackBar("User denied the request for geolocation.", SNACK_BAR_VARIETNS.error));
//                         break;
//                     case error.POSITION_UNAVAILABLE:
//                         dispatch(callSnackBar("Location information is unavailable.", SNACK_BAR_VARIETNS.error));
//                         break;
//                     case error.TIMEOUT:
//                         dispatch(callSnackBar("The request to get user location timed out.", SNACK_BAR_VARIETNS.error));
//                         break;
//                     case error.UNKNOWN_ERROR:
//                         dispatch(callSnackBar("An unknown error occurred.", SNACK_BAR_VARIETNS.error));
//                         break;
//                 }
//                 setLoading(false)
//             });
//         } else {
//             dispatch(callApiAction(
//                 async () => await createAttendenceApi({ userId, lat, log, logType: isCheckedIn ? CALENDAR_ITEM_TYPES.checkOUTLog : CALENDAR_ITEM_TYPES.checkInLog }),
//                 (response) => {
//                     setLoading(false)
//                     setJustCheckedOut(isCheckedIn)
//                     setIsCheckIn(!isCheckedIn)

//                     callBack()
//                 },
//                 (err) => {
//                     dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
//                     setLoading(false)
//                 }
//             ))
//         }

//     }

//     if (!isCheckedIn && !loading) {
//         return <Dialog open={true} >

//             <DialogContent>
//                 <Typography align="center" variant="h5" >
//                     {justCheckout ? "Bbye" : 'Welcome'} {user.data.name}
//                 </Typography>
//                 {justCheckout && <> <Typography align="center" variant="subtitle1" mt={3} >
//                     See you tomorrow
//                 </Typography>

//                     <Typography variant="caption" align="center" mt={3} >
//                         Refresh the page if you want to close this popup
//                     </Typography>

//                 </>}
//                 {!justCheckout && <> <Typography variant="subtitle1" mt={3} mb={3} >
//                     Please Check-in to mark your attendence
//                 </Typography>
//                     <SubmitButton disabled={loading} loading={loading} onClick={onClick} color={"dark"} title={"Check In"} /></>}
//             </DialogContent>
//         </Dialog>
//     }

//     return <Grid container spacing={2} >
//         <Grid item xs={6} >
//             <SubmitButton size="small" disableElevation disabled={loading} loading={loading} onClick={onClick} sx={{ padding: 2, minWidth: "0px" }} color={'error'} title={isCheckedIn ? "Check Out" : "Check In"} />
//         </Grid>
//         <Grid item xs={6} >
//             <SubmitButton size="small" disableElevation disabled={loading} loading={loading} onClick={onClick} sx={{ padding: 2, minWidth: "0px" }} color={'error'} title={isCheckedIn ? "Check Out" : "Check In"} />
//         </Grid>

//     </Grid>


// }
// export default memo(CheckInCheckOutButton)